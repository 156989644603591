// TODO - eventually we'll want to add this to the globals.css file or convert it to a css file so it can use tailwind directives
@import './global-mixins-and-vars.scss';

$toggle-button-hover-color: #595451;

@mixin setHeight($buttonHeight, $paddingTop) {
  height: $buttonHeight;
  border-radius: $buttonHeight; // matches button height

  // center text
  $line-height: 18px; // set to constant to make height calculations easy
  $topAndBottomBorder: 3px;
  // the button font is short and doesn't center vertically automatically so we have to set paddings manually
  // button height = (top and bottom border) + (line height) + (top padding) +  (bottom padding)
  line-height: $line-height;
  padding-top: $paddingTop;
  padding-bottom: $buttonHeight - $topAndBottomBorder - $line-height -
    $paddingTop;
}

@mixin button-base {
  @include setHeight(48px, 12px);
  font-family: Founders Grotesk, Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  letter-spacing: 0.01em;

  // needed for styling <a></a> tags as buttons
  vertical-align: middle;

  // other
  padding-left: 30px;
  padding-right: 30px;
  display: inline-block;
  overflow: hidden;
  cursor: pointer;
  color: $white;
  border: none;
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  text-align: center;

  transition-property: color, background-color;
  transition-duration: 0.2s;

  &:active,
  &:hover {
    color: $white;
    box-shadow: none;
    text-decoration: none;
  }
}

@mixin base-colors($color) {
  background-color: $color;
  border: 1.5px solid $color;
}

@mixin button-base-transitions($activeColor) {
  &:hover {
    @screen small-desktop {
      background-color: $activeColor;
    }
  }

  &:active {
    background-color: $activeColor;
  }
}

@mixin secondary-bgs($textColor, $backgroundColor) {
  color: $textColor;
  border: 1.5px solid $textColor;
  background-color: $white;

  &:hover {
    color: $textColor;
    @screen small-desktop {
      background-color: $backgroundColor;
    }
  }

  &:active {
    color: $textColor;
    background-color: $backgroundColor;
  }
}

@mixin small-button {
  padding: 0px 15px;
  @include setHeight(32px, 4px);
}

@mixin large-button {
  @include setHeight(60px, 18px);

  @screen small-desktop {
    // go back to regular button
    @include setHeight(48px, 12px);
  }
}

@mixin disabled-button {
  &[disabled],
  &.disabled {
    pointer-events: none;
    color: $medium-grey;
    border: 1.5px solid $light-grey;
    background-color: $white;

    &:active,
    &:hover {
      background-color: $white;
    }
  }
}

@mixin circle-button {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

$button-colors: (
  'persimmon': (
    'primary-bg': $persimmon,
    'primary-bg-hover': $pomegranate,
    //
    'secondary-bg': $white,
    'secondary-bg-hover': $white-peach,
    'secondary-text': $persimmon,
    //
    'primary-on-colors-bg': $white,
    'primary-on-colors-bg-hover': $white-peach,
    'primary-on-colors-text': $persimmon,
  ),
  'aqua': (
    'primary-bg': $aqua,
    'primary-bg-hover': $ocean,
    //
    'secondary-bg': $white,
    'secondary-bg-hover': $horizon,
    'secondary-text': $aqua,
    //
    'primary-on-colors-bg': $white,
    'primary-on-colors-bg-hover': $horizon,
    'primary-on-colors-text': $aqua,
  ),
);

@each $name, $value in $button-colors {
  // Primary Buttons
  .fern-small-btn--#{$name} {
    @include button-base;
    @include button-base-transitions(map-get($value, 'primary-bg-hover'));
    @include base-colors(map-get($value, 'primary-bg'));
    @include disabled-button;
    @include small-button;
  }

  .fern-circle-btn--#{$name} {
    @include button-base;
    @include button-base-transitions(map-get($value, 'primary-bg-hover'));
    @include base-colors(map-get($value, 'primary-bg'));
    @include disabled-button;
    @include circle-button;
  }

  .fern-btn--#{$name} {
    @include button-base;
    @include base-colors(map-get($value, 'primary-bg'));
    @include button-base-transitions(map-get($value, 'primary-bg-hover'));
    @include disabled-button;
  }

  .fern-large-btn--#{$name} {
    @include button-base;
    @include button-base-transitions(map-get($value, 'primary-bg-hover'));
    @include base-colors(map-get($value, 'primary-bg'));
    @include disabled-button;
    @include large-button;
  }

  // Secondary Buttons
  .fern-secondary-small-btn--#{$name} {
    @include button-base;
    @include button-base-transitions(map-get($value, 'secondary-bg-hover'));
    @include disabled-button;
    @include small-button;
    @include secondary-bgs(
      map-get($value, 'secondary-text'),
      map-get($value, 'secondary-bg-hover')
    );
  }

  .fern-secondary-btn--#{$name} {
    @include button-base;
    @include button-base-transitions(map-get($value, 'secondary-bg-hover'));
    @include disabled-button;
    @include secondary-bgs(
      map-get($value, 'secondary-text'),
      map-get($value, 'secondary-bg-hover')
    );
  }

  .fern-secondary-large-btn--#{$name} {
    @include button-base;
    @include button-base-transitions(map-get($value, 'secondary-bg-hover'));
    @include disabled-button;
    @include large-button;
    @include secondary-bgs(
      map-get($value, 'secondary-text'),
      map-get($value, 'secondary-bg-hover')
    );
  }

  // Primary on color Buttons
  .fern-primary-on-color-small-btn--#{$name} {
    @include button-base;
    @include button-base-transitions(map-get($value, 'secondary-bg-hover'));
    @include disabled-button;
    @include small-button;
    @include secondary-bgs(
      map-get($value, 'secondary-text'),
      map-get($value, 'secondary-bg-hover')
    );
    border: none;
  }

  .fern-primary-on-color-btn--#{$name} {
    @include button-base;
    @include button-base-transitions(map-get($value, 'secondary-bg-hover'));
    @include disabled-button;
    @include secondary-bgs(
      map-get($value, 'secondary-text'),
      map-get($value, 'secondary-bg-hover')
    );
    border: none;
  }

  .fern-primary-on-color-large-btn--#{$name} {
    @include button-base;
    @include button-base-transitions(map-get($value, 'secondary-bg-hover'));
    @include disabled-button;
    @include large-button;
    @include secondary-bgs(
      map-get($value, 'secondary-text'),
      map-get($value, 'secondary-bg-hover')
    );
    border: none;
  }
}
