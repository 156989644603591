// TODO - eventually we'll want to remove this in favor of tailwind configs (.css)
// This needs to stay in sync with tailwind.config.js
$black: #000000;
$dark-brown: #39302a;
$grey: #6f6965;
$medium-grey: #9c9391;
$light-grey: #dcd8d5;
$lighter-grey: #edebe9;
$lightest-grey: #f5f5f5;
$white: #ffffff;
$ocean: #006073;
$aqua: #007b93;
$sky: #46a0b2;
$daybreak: #99cbd5;
$horizon: #e3f0f3;
$haze: #f0f8fa;
$pomegranate: #cf4a15;
$persimmon: #ed653b;
$papaya: #f68e65;
$white-peach: #fdf6f2;
$lemonade: #ffdc80;
$oak: #c8aa8f;
$light-oak: #deccbc;
$birch: #ece5de;
$sand: #f2f0ed;
$green: #61970a;
$light-green: #eff5e7;

@mixin fern-media-tablet-up {
  @media (min-width: 576px) {
    @content;
  }
}

@mixin fern-media-small-desktop-up {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin fern-media-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin fern-media-xl-desktop-up {
  @media (min-width: 1600px) {
    @content;
  }
}
