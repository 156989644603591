@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --navbar-mobile-height: 60px;
  --navbar-desktop-height: 75px;
}

@layer base {
  /* This will get purged b/c it's part of a tailwind layer */
  .check-if-this-gets-purged {
    border: 1px solid red;
  }
}

/* -------------------- */
/* Fonts */
/* -------------------- */
@layer base {
  html {
    -webkit-font-smoothing: antialiased;
    @apply fern-body text-dark-brown;
  }

  @font-face {
    font-family: 'Founders Grotesk';
    src: url('/next_assets/fonts/founders-grotesk-web-regular.woff');
    font-display: fallback;
  }

  @font-face {
    font-family: 'Founders Grotesk';
    src: url('/next_assets/fonts/founders-grotesk-web-semibold.woff');
    font-weight: bold;
    font-display: fallback;
  }

  @font-face {
    font-family: 'GT Walsheim';
    src: url('/next_assets/fonts/GT-Walsheim-Regular.woff');
    font-display: fallback;
  }

  @font-face {
    font-family: 'GT Walsheim';
    src: url('/next_assets/fonts/GT-Walsheim-Medium.woff');
    font-display: fallback;
    font-weight: 500;
  }

  @font-face {
    font-family: 'GT Walsheim';
    src: url('/next_assets/fonts/GT-Walsheim-Bold.woff');
    font-weight: bold;
    font-display: fallback;
  }

  @font-face {
    font-family: 'GT Walsheim Oblique';
    src: url('/next_assets/fonts/GT-Walsheim-Regular-Oblique.woff');
    font-display: fallback;
  }

  @font-face {
    font-family: 'Raw Soul';
    src: url('/next_assets/fonts/raw_soul-webfont.woff');
    font-display: fallback;
  }

  .fern-xlarge-header {
    @apply font-gt-walsheim;
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 115%;
    letter-spacing: -0.02em;

    @screen small-desktop {
      font-size: 72px;
    }
  }

  .fern-large-header {
    @apply font-gt-walsheim;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;

    line-height: 115%;
    letter-spacing: -0.02em;

    @screen small-desktop {
      font-size: 48px;
    }
  }

  .fern-medium-header {
    @apply font-gt-walsheim;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 115%;
    letter-spacing: -0.02em;

    @screen small-desktop {
      font-size: 34px;
    }
  }

  .fern-small-header {
    @apply font-gt-walsheim;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 115%;
    letter-spacing: -0.01em;

    @screen small-desktop {
      font-size: 24px;
    }
  }

  .fern-xsmall-header {
    @apply font-gt-walsheim;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.01em;
  }

  .fern-xxlarge-body {
    @apply font-founders-grotesk;
    font-size: 26px;
    line-height: 1.5;
    letter-spacing: 0em;
  }

  .fern-xlarge-body {
    @apply font-gt-walsheim;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 150%;
    letter-spacing: -0.01em;

    @screen small-desktop {
      font-size: 24px;
    }
  }

  .fern-large-body {
    @apply font-founders-grotesk;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 150%;

    @screen small-desktop {
      font-size: 20px;
    }
  }

  .fern-large-body-bold {
    @apply font-founders-grotesk;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 150%;

    @screen small-desktop {
      font-size: 20px;
    }
  }

  .fern-button-text {
    @apply font-founders-grotesk;
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 150%;
    letter-spacing: 0.01em;
  }

  .fern-body {
    @apply font-founders-grotesk;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
  }

  .fern-body-bold {
    @apply font-founders-grotesk;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 150%;
  }

  .fern-small-body {
    @apply font-founders-grotesk;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
  }

  .fern-small-body-bold {
    @apply font-founders-grotesk;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 150%;
  }

  .fern-xsmall-body {
    @apply font-founders-grotesk;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
  }

  .fern-xxsmall-body {
    @apply font-founders-grotesk;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 150%;
  }

  .fern-label {
    @apply font-gt-walsheim;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: 0.15em;
    text-transform: uppercase;

    @screen small-desktop {
      font-size: 22px;
    }
  }

  .fern-small-label {
    @apply font-gt-walsheim;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.15em;
    text-transform: uppercase;
  }

  .fern-handwriting {
    @apply font-raw-soul;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 150%;
    letter-spacing: -0.01em;

    @screen small-desktop {
      font-size: 50px;
    }
  }
}

/* -------------------- */
/* grid */
/* -------------------- */
@layer base {
  .fern-grid-column-gap {
    @apply gap-x-30;
  }
  .fern-grid-row-gap {
    @apply gap-y-30;
  }
  .fern-grid-gap {
    @apply gap-30;
  }
  .fern-grid {
    @apply grid;
    @apply fern-grid-column-gap;
    @apply grid-cols-2 tablet:grid-cols-6 small-desktop:grid-cols-12;
  }
  .fern-grid--content {
    @apply fern-grid;
    @apply px-15 max-w-[1440px] mr-auto ml-auto small-desktop:w-[90%];
  }

  .fern-grid--fullwidth {
    @apply col-span-full;
  }
}

/* -------------------- */
/* Links */
/* -------------------- */
@layer base {
  .fern-show-tablet-up {
    display: none;
    @screen tablet {
      display: initial;
    }
  }

  .fern-hide-tablet-up {
    @screen tablet {
      display: none;
    }
  }

  .fern-show-small-desktop-up {
    display: none;
    @screen small-desktop {
      display: initial;
    }
  }

  .fern-hide-small-desktop-up {
    @screen small-desktop {
      display: none;
    }
  }

  .fern-show-desktop-up {
    display: none;
    @screen desktop {
      display: initial;
    }
  }

  .fern-hide-desktop-up {
    @screen desktop {
      display: none;
    }
  }

  .fern-show-xl-desktop-up {
    display: none;
    @screen xl-desktop {
      display: initial;
    }
  }

  .fern-hide-xl-desktop-up {
    @screen xl-desktop {
      display: none;
    }
  }

  .fern-display-none {
    display: none;
  }

  .fern-visibility-hidden {
    visibility: hidden;
  }
}

/* -------------------- */
/* Links */
/* -------------------- */
@layer components {
  .fern-link {
    @apply text-aqua;
    padding: 0px;

    cursor: pointer;
    transition: color 0.2s;
    text-decoration: underline;
    background-color: transparent;
    border: none;
    appearance: none;
    -webkit-appearance: none;

    @apply hover:underline small-desktop:hover:text-ocean;
    @apply active:underline active:text-ocean;
  }

  .fern-clean-link {
    cursor: pointer;
    text-decoration: none;
    @apply text-dark-brown;

    @apply hover:text-dark-brown small-desktop:hover:underline;
    @apply active:underline active:text-dark-brown;
  }

  .fern-large-link-button {
    @apply fern-button-text;
    display: inline-block;
    border-bottom: 5px solid;
    padding-bottom: 5px;
    @apply border-sky;
    transition-property: color, border-color;
    transition-duration: 0.2s;

    @apply small-desktop:hover:border-ocean small-desktop:hover:text-ocean;
    @apply active:border-ocean active:text-ocean;
  }
}

/* -------------------- */
/* Misc */
/* -------------------- */
@layer base {
  /* this is the top padding for anchor tags */
  html {
    --mobile-height: calc(var(--navbar-mobile-height) + 30px);
    --desktop-height: calc(var(--navbar-desktop-height) + 30px);

    scroll-padding-top: var(--mobile-height);

    @screen small-desktop {
      scroll-padding-top: var(--desktop-height);
    }
  }

  @media print {
    body {
      color: black;
    }
    .navbar {
      display: none;
    }
    .footer-container {
      display: none;
    }
  }
}

@layer components {
  .mouseDraggable {
    cursor: grab;
  }

  .mouseDraggable.dragging {
    cursor: grabbing;

    * {
      pointer-events: none;
    }
  }

  .debug {
    @apply border border-pomegranate;
  }
}

/* -------------------- */
/* Blog Posts */
/* -------------------- */
@layer components {
  .cms-copy {
    h1 {
      @apply fern-large-header mb-15;
    }
    h2 {
      @apply fern-medium-header mb-15;
    }
    h3 {
      @apply fern-small-header mb-15;
    }
    h4 {
      @apply fern-xsmall-header mb-15;
    }
    h5 {
      @apply fern-xsmall-header mb-15;
    }
    h6 {
      @apply fern-xsmall-header mb-15;
    }
    p {
      @apply mb-15;
    }
  }

  .blog-post-detail-page,
  .buttercms-wysiwyg-content {
    @apply fern-large-body;
    h1 {
      @apply fern-medium-header mb-30;
    }
    h2 {
      @apply fern-small-header mb-30;
    }
    h3 {
      @apply fern-xsmall-header mb-30;
    }
    h4 {
      @apply fern-xsmall-header mb-30;
    }
    h5 {
      @apply fern-xsmall-header mb-30;
    }
    h6 {
      @apply fern-xsmall-header mb-30;
    }
    p {
      @apply mb-30;
    }
    blockquote {
      @apply border-l-4 border-black;
      @apply italic;
      @apply -ml-30 pl-30;
    }

    pre {
      @apply bg-black text-white p-15 rounded-md;
      @apply mb-30;

      code {
        font-size: 14px;
      }
    }

    table {
      @apply mb-60;
    }

    td {
      @apply border border-light-grey p-15;
    }

    a {
      @apply fern-link;
    }

    figure {
      @apply mb-30;

      img {
        @apply mb-10 w-full;
      }
    }

    figcaption {
      @apply text-center;
    }
  }
}
