%fern-colored-icon-base {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 60px;
  height: 60px;
}

.fern-color-icon--responsive {
  display: block;
  width: 100%;
  height: 100%;
}

.fern-color-icon-cleaning {
  @extend %fern-colored-icon-base;
  background-image: url('./cleaning.svg');
}
.fern-color-icon-cleaning-aqua {
  @extend %fern-colored-icon-base;
  background-image: url('./cleaning-aqua.svg');
}
.fern-color-icon-cleaning-oak {
  @extend %fern-colored-icon-base;
  background-image: url('./cleaning-oak.svg');
}
.fern-color-icon-truck {
  @extend %fern-colored-icon-base;
  background-image: url('./truck.svg');
}
.fern-color-icon-truck-aqua {
  @extend %fern-colored-icon-base;
  background-image: url('./truck-aqua.svg');
}
.fern-color-icon-truck-oak {
  @extend %fern-colored-icon-base;
  background-image: url('./truck-oak.svg');
}
.fern-color-icon-calendar {
  @extend %fern-colored-icon-base;
  background-image: url('./calendar.svg');
}
.fern-color-icon-calendar-aqua {
  @extend %fern-colored-icon-base;
  background-image: url('./calendar-aqua.svg');
}
.fern-color-icon-calendar-oak {
  @extend %fern-colored-icon-base;
  background-image: url('./calendar-oak.svg');
}
.fern-color-icon-swap {
  @extend %fern-colored-icon-base;
  background-image: url('./swap.svg');
}
.fern-color-icon-swap-aqua {
  @extend %fern-colored-icon-base;
  background-image: url('./swap-aqua.svg');
}
.fern-color-icon-swap-oak {
  @extend %fern-colored-icon-base;
  background-image: url('./swap-oak.svg');
}
.fern-color-icon-love {
  @extend %fern-colored-icon-base;
  background-image: url('./love.svg');
}
.fern-color-icon-love-aqua {
  @extend %fern-colored-icon-base;
  background-image: url('./love-aqua.svg');
}
.fern-color-icon-love-oak {
  @extend %fern-colored-icon-base;
  background-image: url('./love-oak.svg');
}
.fern-color-icon-couch {
  @extend %fern-colored-icon-base;
  background-image: url('./couch.svg');
}
.fern-color-icon-couch-aqua {
  @extend %fern-colored-icon-base;
  background-image: url('./couch-aqua.svg');
}
.fern-color-icon-couch-oak {
  @extend %fern-colored-icon-base;
  background-image: url('./couch-oak.svg');
}
.fern-color-icon-return {
  @extend %fern-colored-icon-base;
  background-image: url('./return.svg');
}
.fern-color-icon-return-aqua {
  @extend %fern-colored-icon-base;
  background-image: url('./return-aqua.svg');
}
.fern-color-icon-return-oak {
  @extend %fern-colored-icon-base;
  background-image: url('./return-oak.svg');
}
.fern-color-icon-buyout {
  @extend %fern-colored-icon-base;
  background-image: url('./buyout.svg');
}
.fern-color-icon-buyout-aqua {
  @extend %fern-colored-icon-base;
  background-image: url('./buyout-aqua.svg');
}
.fern-color-icon-buyout-oak {
  @extend %fern-colored-icon-base;
  background-image: url('./buyout-oak.svg');
}
.fern-color-icon-brandmark {
  @extend %fern-colored-icon-base;
  background-image: url('./brandmark.svg');
}
.fern-color-icon-brandmark-aqua {
  @extend %fern-colored-icon-base;
  background-image: url('./brandmark-aqua.svg');
}
.fern-color-icon-brandmark-oak {
  @extend %fern-colored-icon-base;
  background-image: url('./brandmark-oak.svg');
}
.fern-color-icon-checkmark {
  @extend %fern-colored-icon-base;
  background-image: url('./checkmark.svg');
}
.fern-color-icon-checkmark-aqua {
  @extend %fern-colored-icon-base;
  background-image: url('./checkmark-aqua.svg');
}
.fern-color-icon-checkmark-oak {
  @extend %fern-colored-icon-base;
  background-image: url('./checkmark-oak.svg');
}
.fern-color-icon-return-truck {
  @extend %fern-colored-icon-base;
  background-image: url('./return-truck.svg');
}
.fern-color-icon-return-truck-aqua {
  @extend %fern-colored-icon-base;
  background-image: url('./return-truck-aqua.svg');
}
.fern-color-icon-return-truck-oak {
  @extend %fern-colored-icon-base;
  background-image: url('./return-truck-oak.svg');
}
.fern-color-icon-house {
  @extend %fern-colored-icon-base;
  background-image: url('./house.svg');
}
.fern-color-icon-house-aqua {
  @extend %fern-colored-icon-base;
  background-image: url('./house-aqua.svg');
}
.fern-color-icon-house-oak {
  @extend %fern-colored-icon-base;
  background-image: url('./house-oak.svg');
}
.fern-color-icon-assembly {
  @extend %fern-colored-icon-base;
  background-image: url('./assembly.svg');
}
.fern-color-icon-assembly-aqua {
  @extend %fern-colored-icon-base;
  background-image: url('./assembly-aqua.svg');
}
.fern-color-icon-assembly-oak {
  @extend %fern-colored-icon-base;
  background-image: url('./assembly-oak.svg');
}
.fern-color-icon-living-room {
  @extend %fern-colored-icon-base;
  background-image: url('./living-room.svg');
}
.fern-color-icon-dining-room {
  @extend %fern-colored-icon-base;
  background-image: url('./dining-room.svg');
}
.fern-color-icon-bedroom {
  @extend %fern-colored-icon-base;
  background-image: url('./bedroom.svg');
}
.fern-color-icon-office {
  @extend %fern-colored-icon-base;
  background-image: url('./office.svg');
}
.fern-color-icon-couch-love {
  @extend %fern-colored-icon-base;
  background-image: url('./couch-love.svg');
}
.fern-color-icon-couch-love-aqua {
  @extend %fern-colored-icon-base;
  background-image: url('./couch-love-aqua.svg');
}
.fern-color-icon-couch-love-oak {
  @extend %fern-colored-icon-base;
  background-image: url('./couch-love-oak.svg');
}

.fern-color-icon-couch-premium {
  @extend %fern-colored-icon-base;
  background-image: url('./couch-premium.svg');
}
.fern-color-icon-couch-premium-aqua {
  @extend %fern-colored-icon-base;
  background-image: url('./couch-premium-aqua.svg');
}
.fern-color-icon-couch-premium-oak {
  @extend %fern-colored-icon-base;
  background-image: url('./couch-premium-oak.svg');
}

.fern-color-icon-wellness {
  @extend %fern-colored-icon-base;
  background-image: url('./wellness.svg');
}
.fern-color-icon-wellness-aqua {
  @extend %fern-colored-icon-base;
  background-image: url('./wellness-aqua.svg');
}
.fern-color-icon-wellness-oak {
  @extend %fern-colored-icon-base;
  background-image: url('./wellness-oak.svg');
}

.fern-color-icon-cx {
  @extend %fern-colored-icon-base;
  background-image: url('./cx.svg');
}
.fern-color-icon-cx-aqua {
  @extend %fern-colored-icon-base;
  background-image: url('./cx-aqua.svg');
}
.fern-color-icon-cx-oak {
  @extend %fern-colored-icon-base;
  background-image: url('./cx-oak.svg');
}
.fern-color-icon-chaise-depth {
  @extend %fern-colored-icon-base;
  background-image: url('./chaise-depth.svg');
}
.fern-color-icon-non-chaise-depth {
  @extend %fern-colored-icon-base;
  background-image: url('./non-chaise-depth.svg');
}
.fern-color-icon-seat-height {
  @extend %fern-colored-icon-base;
  background-image: url('./seat-height.svg');
}
.fern-color-icon-height-range {
  @extend %fern-colored-icon-base;
  background-image: url('./height-range.svg');
}
.fern-color-icon-clearance {
  @extend %fern-colored-icon-base;
  background-image: url('./clearance.svg');
}
.fern-color-icon-weight {
  @extend %fern-colored-icon-base;
  background-image: url('./weight.svg');
}
.fern-color-icon-depth {
  @extend %fern-colored-icon-base;
  background-image: url('./depth.svg');
}
.fern-color-icon-height {
  @extend %fern-colored-icon-base;
  background-image: url('./height.svg');
}
.fern-color-icon-width {
  @extend %fern-colored-icon-base;
  background-image: url('./width.svg');
}
.fern-color-icon-multifamily {
  @extend %fern-colored-icon-base;
  background-image: url('./multifamily.svg');
}
.fern-color-icon-commercial {
  @extend %fern-colored-icon-base;
  background-image: url('./commercial.svg');
}
.fern-color-icon-partnership {
  @extend %fern-colored-icon-base;
  background-image: url('./partnership.svg');
}
.fern-color-icon-staging {
  @extend %fern-colored-icon-base;
  background-image: url('./staging.svg');
}
.fern-color-icon-sky-checkmark {
  @extend %fern-colored-icon-base;
  background-image: url('./sky-checkmark.svg');
}
.fern-color-icon-monthly-payment {
  @extend %fern-colored-icon-base;
  background-image: url('./monthly-payment.svg');
}
.fern-color-icon-monthly-payment-aqua {
  @extend %fern-colored-icon-base;
  background-image: url('./monthly-payment-aqua.svg');
}
.fern-color-icon-monthly-payment-oak {
  @extend %fern-colored-icon-base;
  background-image: url('./monthly-payment-oak.svg');
}
.fern-color-icon-rent-or-buy {
  @extend %fern-colored-icon-base;
  background-image: url('./rent-or-buy.svg');
}
.fern-color-icon-rent-or-buy-aqua {
  @extend %fern-colored-icon-base;
  background-image: url('./rent-or-buy-aqua.svg');
}
.fern-color-icon-rent-or-buy-oak {
  @extend %fern-colored-icon-base;
  background-image: url('./rent-or-buy-oak.svg');
}
.fern-color-icon-low-price {
  @extend %fern-colored-icon-base;
  background-image: url('./low-price.svg');
}
.fern-color-icon-low-price-aqua {
  @extend %fern-colored-icon-base;
  background-image: url('./low-price-aqua.svg');
}
.fern-color-icon-low-price-oak {
  @extend %fern-colored-icon-base;
  background-image: url('./low-price-oak.svg');
}
.fern-color-icon-design {
  @extend %fern-colored-icon-base;
  background-image: url('./design.svg');
}
.fern-color-icon-design-aqua {
  @extend %fern-colored-icon-base;
  background-image: url('./design-aqua.svg');
}
.fern-color-icon-design-oak {
  @extend %fern-colored-icon-base;
  background-image: url('./design-oak.svg');
}
