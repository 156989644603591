.container {
  position: fixed;
  top: 75px;
  left: 15px;
  right: 15px;
  z-index: 1000;
  @screen small-desktop {
    width: 360px;
    left: auto;
    top: 90px;
  }
}

@keyframes toastIn {
  0% {
    transform: scale(0.5);
    opacity: 0;
    max-height: 0;
  }
  90% {
    transform: scale(1.1);
    max-height: 1000px;
  }
  100% {
    transform: scale(1);
    opacity: 1;
    max-height: 1000px;
  }
}

@keyframes toastOut {
  0% {
    transform: scale(1);
    opacity: 1;
    max-height: 300px;
  }
  100% {
    transform: scale(0.75);
    opacity: 0;
    max-height: 0;
    margin: 0;
    padding: 0;
  }
}

.toast {
  padding: 15px;
  color: theme('colors.dark-brown');
  margin-bottom: 5px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  animation: toastIn 0.3s;
}
.notice {
  background-color: theme('colors.light-green');
}
.alert {
  background-color: theme('colors.sand');
}
.error {
  background-color: theme('colors.white-peach');
}
.info {
  background-color: theme('colors.horizon');
}
.closing {
  animation: toastOut 0.3s forwards;
}
