.icon-text-button {
  transition-property: color;
  transition-duration: 0.2s;
  display: flex;
  align-items: center;

  &:hover {
    @apply text-dark-brown;
  }

  &:active {
    @apply text-papaya;
  }

  @screen small-desktop {
    @apply text-dark-brown;

    &:hover {
      @apply text-papaya;
    }
  }

  &.show-on-mobile {
    i {
      @apply text-dark-brown;
      transition-property: color;
      transition-duration: 0.2s;
    }
    &:active i {
      @apply text-papaya;
    }

    @screen small-desktop {
      &:hover i {
        @apply text-papaya;
      }
    }

    span {
      display: inline !important;
    }
  }
}

.icon-text-button--text {
  display: none;
  @apply text-dark-brown;

  @screen small-desktop {
    display: inline;
  }
}
